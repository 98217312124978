<template>
  <AGSSprite />

  <div class="layout">
    <router-view/>
  </div>
</template>

<script>
import AGSSprite from '@/components/AGSSprite';

export default {
  name: 'App',

  components: {
    AGSSprite
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
