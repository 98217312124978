import { createStore } from 'vuex'
import { FIRST_COLOR, SECOND_COLOR,THIRD_COLOR, APPLE_COLOR } from '@/constants/index';

export default createStore({
  state: {
    firstColor: FIRST_COLOR,
    secondColor: SECOND_COLOR,
    thirdColor: THIRD_COLOR,
    appleColor: APPLE_COLOR,
  },

  mutations: {
    setFirstColor(state, val) {
      state.firstColor = val;
    },

    setSecondColor(state, val) {
      state.secondColor = val;
    },

    setThirdColor(state, val) {
      state.thirdColor = val;
    },

    setAppleColor(state, val) {
      state.appleColor = val;
    }
  },
})
