import { createRouter, createWebHistory } from 'vue-router';
import { PAGE_HOME, PAGE_OPTIONS, PAGE_GAME } from '@/constants/router';

const routes = [
  {
    path: '/',
    name: PAGE_HOME,
    component: () => import('@/pages/HomePage.vue')
  },

  {
    path: '/options',
    name: PAGE_OPTIONS,
    component: () => import('@/pages/OptionsPage.vue')
  },
  {
    path: '/game',
    name: PAGE_GAME,
    component: () => import('@/pages/GamePage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
